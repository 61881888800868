import { ProductField } from 'Task/ProductTask/types'
import { apiClient } from './client'

export const createProductDataObject = async (workspace_id: string) =>
  await apiClient.post(`/product/${workspace_id}/product_data`)

export const updateProductDataObject = async (
  workspace_id: string,
  params: any
) => await apiClient.put(`/product/${workspace_id}/product_data`, params)

export const deleteProductDataObject = async (workspace_id: string) =>
  await apiClient.delete(`/product/${workspace_id}/product_data`)

export const createProductTask = async (
  workspace_id: string,
  fields: ProductField[],
  showVariants: boolean
) => {
  await apiClient.post(`/product/${workspace_id}/product_task`, {
    fields,
    showVariants
  })
}

export const getWorkspaceWithProductData = async (workspace_id: string) =>
  await apiClient.get(`/product/${workspace_id}`)
