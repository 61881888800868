import { apiClient } from './client'

import { FormTemplate, FormTemplateStatus, Phase } from '.prisma/client'
import { AxiosResponse } from 'axios'

export type FormTemplateWithPhase = FormTemplate & {
  phase: Phase
}

// export const getFormTemplate: ApiGetFn<FormTemplate> = async (id: string) =>
//   await apiClient.get(`/workspace/${id}`)
export const listFormTemplates = async (): Promise<
  AxiosResponse<FormTemplateWithPhase[]>
> => await apiClient.get('/form_template')

export const archiveFormTemplateApi = async (
  form_template_id: string
): Promise<AxiosResponse<FormTemplate>> =>
  await apiClient.post(`/form_template/${form_template_id}`, {
    status: FormTemplateStatus.ARCHIVED
  })

export const putFormTemplate = async (
  template: FormTemplate
): Promise<AxiosResponse<FormTemplateWithPhase>> =>
  await apiClient.put(`/form_template`, { template })
