import { apiClient } from './client'
import { ApiPostFn, ApiListFn } from './types'
import { Workspace, WorkspaceStatus } from '.prisma/client'
import { AvailableTask } from 'TaskGenerator'
import { AxiosResponse } from 'axios'

type PostWorkspaceParams = {
  name: string
  store_id: string
  offer_amount?: number
  inventory_amount?: number
  accept_date?: string
}
export const archiveWorkspaceApi = async (
  workspace_id: string
): Promise<AxiosResponse<Workspace>> =>
  await apiClient.post(`/workspace/${workspace_id}`, {
    status: WorkspaceStatus.ARCHIVED
  })

export const getWorkspace = async (workspace_id: string) =>
  await apiClient.get(`/workspace/${workspace_id}`)

export const listWorkspaces: ApiListFn<Workspace> = async () =>
  await apiClient.get('/workspace')

export const postWorkspace: ApiPostFn<PostWorkspaceParams, Workspace> = async (
  params: PostWorkspaceParams
) => await apiClient.post(`/workspace`, params)

export const listAvailableTasks = async (workspace_id: string) =>
  await apiClient.get(`/workspace/${workspace_id}/available_tasks`)

export const generateAvailableTask = async (available_task: AvailableTask) =>
  await apiClient.post(
    `/workspace/${available_task.workspace_id}/available_tasks`,
    { available_task }
  )

export const listWorkspaceVendors = async (workspace_id: string) =>
  await apiClient.get(`/workspace/${workspace_id}/vendors`)

export const updateWorkspaceConfig = async (
  workspace_id: string,
  config: any
) => await apiClient.post(`/workspace/${workspace_id}/config`, config)

export const createPartner = async ({
  workspace_id,
  partner_name,
  partner_role
}) =>
  await apiClient.post(`/workspace/${workspace_id}/partner`, {
    name: partner_name,
    partnerType: partner_role
  })

export const editPartner = async ({
  workspace_id,
  partner_id,
  partner_name,
  partner_role
}) =>
  await apiClient.put(`/workspace/${workspace_id}/partner`, {
    partner_id: partner_id,
    name: partner_name,
    partnerType: partner_role
  })
