import { apiClient } from './client'
import { Prisma, Task, TaskStatus } from '.prisma/client'
import { AxiosResponse } from 'axios'
import { ProductDataAction } from 'ProductTask/actions'

// export const postTask: ApiPostFn<string, Task> = async (title: string) =>
//   await apiClient.post(`/Task`, { title })
// export const getTask: ApiGetFn<Task> = async (id) =>
//   await apiClient.get(`/Task/${id}`)

// export const listTasks: ApiListFn<Task> = async () =>
//   await apiClient.get('/Task')

export const archiveTask = async (id: string): Promise<AxiosResponse<Task>> =>
  await apiClient.post(`/task/${id}`, { status: TaskStatus.ARCHIVED })

export const markTaskAsComplete = async (
  id: string
): Promise<AxiosResponse<Task>> =>
  await apiClient.post(`/task/${id}`, { status: TaskStatus.COMPLETE })

export const markTaskAsIncomplete = async (
  id: string
): Promise<AxiosResponse<Task>> =>
  await apiClient.post(`/task/${id}`, { status: TaskStatus.INCOMPLETE })

export const saveSubmission = async (
  id: string,
  submission: Prisma.JsonValue
): Promise<AxiosResponse<Task>> =>
  await apiClient.post(`/task/${id}`, { submission })

export const updateFormTaskSchema = async (task_id: string, schema: Object) =>
  await apiClient.put(`/task/update_form_schema`, { task_id, schema })

export const saveProductData = async (
  id: string,
  data: any,
  type: ProductDataAction,
  fields?: string[]
): Promise<AxiosResponse<Task>> =>
  await apiClient.post(`/product/task/${id}/?type=${type}`, { data, fields })
