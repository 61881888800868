import { ApiDisconnectFn } from '.'
import { GroupWithUsersAndInvites } from 'service/prisma/types'
import { apiClient } from './client'
import { ApiGetFn, ApiListFn, ApiPostFn } from './types'

export const postGroup: ApiPostFn<string, GroupWithUsersAndInvites> = async (
  title: string
) => await apiClient.post(`/group`, { title })
export const getGroup: ApiGetFn<GroupWithUsersAndInvites> = async (id) =>
  await apiClient.get(`/group/${id}`)

export const listGroups: ApiListFn<GroupWithUsersAndInvites> = async () =>
  await apiClient.get('/group')

export const disconnectFromGroup: ApiDisconnectFn = async (
  id,
  modelToDisconnect
) =>
  await apiClient.delete(
    `/group/${id}/${modelToDisconnect.model}/${modelToDisconnect.id}`
  )
