import { AxiosResponse } from 'axios'
import { apiClient } from './client'

export type SalesData = {
  SALES: number
  DAY: string
  STORE_ID: string
}
export const getSales = async (
  store_id: string,
  current?: boolean
): Promise<AxiosResponse<SalesData[]>> =>
  await apiClient.get(`/sales/${store_id}`, { params: { current: current } })

export const postHistoricSales = async (
  store_id: string,
  accept_date: string
) => {
  await apiClient.post(`/sales/${store_id}`, {
    accept_date: accept_date
  })
}
